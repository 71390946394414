import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Disclosure' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Disclosure" />
        <p>KayakFishingMan.com is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to (kayakfishingman.com) (amazon.com, or endless.com, MYHABIT.com, SmallParts.com, or AmazonWireless.com)</p>
        <Divider />
      </Main>
    </Stack>
  </>
)
